import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import backgroundImage from '../images/sunset-landing.jpg'; // Import the image
import '../style/LandingPage.css'; // Import CSS for styling

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="background-image">
        <img src={backgroundImage} alt="Background" />
      </div>
      <div className="content-transparent">
        <div className="text-content">
          <h1>just a landing page</h1>
          <p>....</p>
          <p>that is all</p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
